/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "세 번째 에피소드는 @Outsideris 님을 모시고 GitHub Universe, HashiConf에 대해서 이야기 했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1407"
  }, "GitHub Universe 2018 참석기 #1")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1408"
  }, "GitHub Universe 2018 참석기 #2")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1409"
  }, "HashiConf 2018 참석기 #1")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1410"
  }, "HashiConf 2018 참석기 #2")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.booking.com/hotel/us/pod-room-o-39-farrell-st-union-square.ko.html"
  }, "outsider님이 묶었던 캡슐호텔 - 포드 룸 오패럴 스트리트 유니언 스퀘어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/Palace+of+Fine+Arts+Theatre/@37.8029619,-122.4513816,17z/data=!3m1!4b1!4m5!3m4!1s0x808586d51696be45:0x92d8d1553a44df2c!8m2!3d37.8029577!4d-122.4491929"
  }, "GitHub Universe 행사장 - Palace of Fine Arts Theatre")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://octoverse.github.com/"
  }, "GitHub 사용 통계 Octoverse")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://learn.hashicorp.com/"
  }, "HashiCorp Learn")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/terraform-collaboration-for-everyone"
  }, "Terraform Collaboration for Everyone")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
